import React, { useEffect, useState } from 'react';
import './Cards.css';
import ReactPlayer from 'react-player';

function Cards() {
  const [mostLikedVideo, setMostLikedVideo] = useState(null);

  useEffect(() => {
    fetchMostLikedVideo();
  }, []);

  const fetchMostLikedVideo = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&channelId=${process.env.REACT_APP_CHANNEL_ID}&part=snippet&order=viewCount&type=video&maxResults=4`
      );
      const data = await response.json();

      if (data.items && data.items.length > 0) {
        const videoId = data.items[0].id.videoId;
        setMostLikedVideo(`https://www.youtube.com/watch?v=${videoId}`);
      }
    } catch (error) {
      console.error('Error fetching most liked video:', error);
    }
  };

  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <h1>Watch my most liked Video</h1>
          {mostLikedVideo ? (
            <ReactPlayer url={mostLikedVideo} controls={true} playbackRate={1} width='100%' height='70vh' volume={0.4} />
          ) : (
            <p>Loading most liked video...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cards;
