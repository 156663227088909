import React from 'react';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Videos from './pages/Videos';


function App() {
  
  return (
    <Router>
      <Routes>
        <Route path='/videos' element={<Videos/>} />
        <Route path='/' exact element={ <Home />} />
      </Routes>
    </Router>
  );
}

export default App;