import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function redirect(url) {
  window.location.url = url
}

function Footer(props) {

  if (props.showHalf) {
    return (
      <div>
        <section class='social-media'>
          <div class='social-media-wrap'>
            <div class='footer-logo'>
              <Link to='/' className='social-logo'>
                Explorer Ashish
              </Link>
            </div>

            <div class='social-icons'>
             <div class='footer-link-items'>

               <a href="https://www.instagram.com/explorer_aswe/" >Instagram</a>
               <a href="https://www.youtube.com/channel/UCRGfLTwSSY0aYsxGUPGWsnQ" >Youtube</a>
              
             </div>
          
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    
    return (
      <div className='footer-container'>
        {/* <section className='footer-subscription'>
          <p className='footer-subscription-heading'>
            Join the Adventure newsletter to receive our best vacation deals
          </p>
          <p className='footer-subscription-text'>
            You can unsubscribe at any time.
          </p>
          <div className='input-areas'>
            <form>
              <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Your Email'
              />
              <Button buttonStyle='btn--outline'>Subscribe</Button>
            </form>
          </div>
        </section> */}
       
           <div class='footer-links'>
           <div className='footer-link-wrapper'>
             {/* <div class='footer-link-items'>
               <h2>About Me</h2>
               <Link to='/sign-up'>How it works</Link>
               <Link to='/'>Testimonials</Link>
               <Link to='/'>Careers</Link>
               <Link to='/'>Investors</Link>
               
             </div> */}
             {/* <div class='footer-link-items'> */}
               {/* <h2>About Me</h2>
               <Link to='/professional'> Professional life</Link> */}
               {/* <Link to='/'>Contact</Link>
               <Link to='/'>Support</Link> */}
               {/* <Link to='/'>Destinations</Link>
               <Link to='/'>Sponsorships</Link> */}
             {/* </div> */}
           </div>
           <div className='footer-link-wrapper'>
             {/* <div class='footer-link-items'>
               <h2>Videos</h2>
               <Link to='/'>Submit Video</Link>
               <Link to='/'>Ambassadors</Link>
               <Link to='/'>Agency</Link>
               <Link to='/'>Influencer</Link>
             </div> */}
             <div class='footer-link-items'>
               <h2>Social Media</h2>

               <a href="https://www.instagram.com/explorer_ashish_official/" >Instagram</a>
               <a href="https://www.youtube.com/channel/UCRGfLTwSSY0aYsxGUPGWsnQ" >Youtube</a>
               {/* <a target="_blank" href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies">Facebook</a> */}
               
               {/* <a target="_blank" href="">Twitter</a> */}
               {/* <Link to='/'>Twitter</Link> */}
             </div>
           </div>
         </div>
        
        <section class='social-media'>
          <div class='social-media-wrap'>
            <div class='footer-logo'>
              <Link to='/' className='social-logo'>
                Explorer Ashish
                {/* <i class='fab fa-typo3' /> */}
              </Link>
            </div>
            {/* <small class='website-rights'>Explorer Ashish © 2020</small> */}
            <div class='social-icons'>
              
              <a class='social-icon-link facebook' target="_blank" href="/"><i class='fab fa-facebook-f' /></a>
              <a class='social-icon-link instagram' target="_blank" href="https://www.instagram.com/explorer_ashish_official/"><i class='fab fa-instagram' /></a>
              <a class='social-icon-link youtube' target="_blank" href="https://www.youtube.com/channel/UCRGfLTwSSY0aYsxGUPGWsnQ"><i class='fab fa-youtube' /></a>
              <a class='social-icon-link twitter' target="_blank" href=""><i class='fab fa-twitter' /></a>
  
          
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Footer;