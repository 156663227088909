import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import ReactPlayer from 'react-player';
import './VideoListItem.css';
import '../blogComponents/BlogDetails.css' // Import your CSS file

function VideoListItem(props) {
  return (
    <div className="video-card">
      <div className="video-spacing">
        <ReactPlayer url={props.url} controls={true} playbackRate={1} width="100%" volume={0.4} />
      </div>
    </div>
  );
}

export default VideoListItem;
