import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import '../components/videoComponents/VideoListItem.css';
import Footer from '../components/Footer';
import VideoListItem from '../components/videoComponents/VideoListItem';
import Navbar from '../components/Navbar';
import AdComponent from '../../src/components/googleAdComponent';

export default function Videos() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageToken, setPageToken] = useState('');
  const videosPerAd = 6; // Number of videos per AdComponent
  let videoCount = 0; // Counter for videos

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchLatestVideos();
  }, []);

  const fetchLatestVideos = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&channelId=${process.env.REACT_APP_CHANNEL_ID}&part=snippet,id&order=date&maxResults=10&pageToken=${pageToken}`
      );

      const newVideos = response.data.items;
      setVideos((prevVideos) => [...prevVideos, ...newVideos]);
      setPageToken(response.data.nextPageToken);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching YouTube videos:', error);
      setLoading(false);
    }
  };

  const insertAdComponents = () => {
    const items = [];

    for (let i = 0; i < videos.length; i++) {
      videoCount++;
      items.push(
        <VideoListItem
          key={videos[i].id.videoId}
          url={`https://www.youtube.com/watch?v=${videos[i].id.videoId}`}
        />
      );

      if (videoCount % videosPerAd === 0) {
        items.push(
          <AdComponent key={`ad-${i}`} className="ad-card" />
        );
      }
    }

    return items;
  };

  return (
    <>
      <Navbar />
      <div className="videos">
        <div className="video_container">
          <div className="cards__wrapper">
            <h1>My Vlogs</h1>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="video_wrapper">
              <div className="card-columns listVideo">
                {insertAdComponents()}
              </div>
              {pageToken && (
                <button
                  onClick={fetchLatestVideos}
                  style={{
                    width: '100%',
                    minHeight: '50px',
                    backgroundColor: '#fff',
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
