import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount() {
    // You can leave this part if you want to push the ad, or omit it if you're not using it for development
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    // Define whether the ad slot is square or rectangular
    const adSlotSquare = this.props.adSlotSquare;
    const slot = adSlotSquare ? '5170434221' : '5170434221';

    // Style for the card, adjust this as needed
    const cardStyle = {
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 0px 12px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      overflow: 'hidden',
      marginBottom: '20px',
      backgroundColor: 'rgba(66, 66, 66, 0.1)',
      transition: 'transform 0.2s ease-in-out',
      backdropFilter: 'blur(15px)',
      minHeight: '200px', // Adjust the height to match your videos
    };

    return (
      <div style={cardStyle} className="ad-card">
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client={process.env.PUB_ID}
          data-ad-slot={slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      </div>
    );
  }
}


